import sortBy from "lodash.sortby";
import uniqBy from "lodash.uniqby";
import React, { useState } from "react";
import Button from "../Button/StandardButton/Button";
import { Col, Row } from "../Grid";
import Text from "../Text/Text";
import GetText, { useString } from "../../i18n/GetText";
import { useSearchParam } from "../../utils/useSearchParam";
import BuyPageLayout from "../../layouts/BuyPageLayout/BuyPageLayout";
import { SanityAccessory, SanityAccessoryCategory } from "../../model/buy";
import AccessoryCard from "../AccessoryCard/AccessoryCard";
import BuyFilter from "../BuyFilter/BuyFilter";
import styles from "./AccessoriesPageContent.module.scss";
import { navigateWithParamsForFiltering } from "../../utils/navigate-with-params";

// Must be even to look good.
const sliceSize = 20;

interface AccessoriesPageContentProps {
  title: string;
  description: string;
  accessories: SanityAccessory[];
}

const AccessoriesPageContent = ({
  title,
  description,
  accessories
}: AccessoriesPageContentProps) => {
  const categoryName = useSearchParam("category");
  const subcategoryName = useSearchParam("subcategory");
  const categoryString = useString("category");
  const subcategoryString = useString("subcategory");
  const allSubcategories = uniqBy(
    accessories.map(r => r.subcategory).filter(Boolean),
    sc => sc._id
  );
  const allCategories: SanityAccessoryCategory[] = sortBy(
    uniqBy(allSubcategories.map(sc => sc.category).filter(Boolean), c => c._id),
    c => c.name
  );
  const subcategories = allSubcategories.filter(
    sc => sc.category.name === categoryName
  );

  const categoryOptions = [
    {
      label: (
        <Text className={styles.option} as="span" variant="default">
          <GetText id="all-categories" />
        </Text>
      ),
      value: ""
    },
    ...allCategories.map(c => ({
      label: (
        <Text className={styles.option} as="span" variant="default">
          {c.name}
        </Text>
      ),
      value: c.name
    }))
  ];

  const subcategoryOptions = [
    {
      label: (
        <Text className={styles.option} as="span" variant="default">
          <GetText id="all-subcategories" />
        </Text>
      ),
      value: ""
    },
    ...subcategories.map(c => ({
      label: (
        <Text className={styles.option} as="span" variant="default">
          {c.name}
        </Text>
      ),
      value: c.name
    }))
  ];

  const filteredAccessories = accessories.filter(
    r =>
      (!categoryName || r.subcategory?.category.name === categoryName) &&
      (!subcategoryName || r.subcategory?.name === subcategoryName)
  );
  const [slices, setSlices] = useState(1);
  const slicedAccessores = filteredAccessories.slice(0, slices * sliceSize);

  const navigateTo = (category?: string, subcategory?: string) => {
    setSlices(1);
    const parts: Record<string, string | undefined> = {
      category,
      subcategory
    };
    navigateWithParamsForFiltering(parts);
  };

  const filterRows = (
    <Row>
      <Col offsetMd={1} md={3} sm={4} xs={12}>
        <BuyFilter
          value={categoryName}
          placeholderId="category"
          placeholderFallback={categoryString}
          onChange={value => navigateTo(value, undefined)}
          options={categoryOptions}
        />
      </Col>
      <Col md={3} sm={4} xs={12}>
        <BuyFilter
          isDisabled={subcategoryOptions.length === 0}
          value={subcategoryName}
          placeholderId="subcategory"
          placeholderFallback={subcategoryString}
          onChange={value => navigateTo(categoryName!, value)}
          options={subcategoryOptions}
        />
      </Col>
    </Row>
  );
  return (
    <BuyPageLayout
      title={title}
      description={description}
      filterRows={filterRows}
      gradient="polarBlue"
    >
      <Row className={styles.cards}>
        {slicedAccessores.map((accessory, index) => (
          <Col
            key={accessory._id}
            className={styles.card}
            xs={12}
            sm={6}
            md={5}
            offsetMd={index % 2 === 0 ? 1 : 0}
          >
            <AccessoryCard accessory={accessory} key={accessory._id} />
          </Col>
        ))}
      </Row>
      <Row justify="center">
        <Col md={10} className={styles.loadCol}>
          {slices * sliceSize < filteredAccessories.length && (
            <Button primary onClick={() => setSlices(slices + 1)}>
              <GetText id="show-more" />
            </Button>
          )}
        </Col>
      </Row>
    </BuyPageLayout>
  );
};

export default AccessoriesPageContent;
