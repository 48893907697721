import React from "react";
import CardContent from "../../components/CardContent/CardContent";
import Link from "../../components/Link/Link";
import { imageUrlBuilder } from "../../utils/images";
import { SanityAccessory } from "../../model/buy";
import AccessoryTagInfo from "../AccessoryTagInfo/AccessoryTagInfo";
import SupplierCardPart from "../SupplierCardPart/SupplierCardPart";
import styles from "./AccessoryCard.module.scss";
import Card from "../../components/Card/Card";

interface AccessoryCardProps {
  accessory: SanityAccessory;
}

const AccessoryCard = ({ accessory }: AccessoryCardProps) => (
  <Card className={styles.root} dir="ltr">
    <Link to={accessory.url}>
      <div className={styles.imageArea}>
        <img
          className={styles.image}
          loading="lazy"
          height="231"
          alt=""
          src={
            imageUrlBuilder
              .image(accessory.image)
              .height(231) // 4:3 images look good. We can live with a little wider.
              .width(308)
              .fit("fillmax")
              .bg("bfbfbf")
              .ignoreImageParams()
              .url()!
          }
        />
      </div>
      <div className={styles.content}>
        <CardContent
          titleBefore={
            <SupplierCardPart
              name={accessory.supplier?.name || "Unknown"}
              type="accessory"
            />
          }
          title={accessory.name}
          titleVariant="h2"
          text={accessory.fields.excerpt}
          textMaxLines={3}
          tags={<AccessoryTagInfo article={accessory} />}
        />
      </div>
    </Link>
  </Card>
);

export default AccessoryCard;
